import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { searchConfig } from "../configs/search";
import SearchIcon from "../svgs/search.svg";
import { ButtonWithTooltip } from "./ButtonWithTooltip";

interface Props {
  isExperimentalHeader?: boolean;
  isStickyHeader?: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
  uhSearchGradientStyle?: boolean;
  partner?: string;
}

export const SearchButton: FC<Props> = ({
  isStickyHeader,
  buttonRef,
  uhSearchGradientStyle,
  partner = "none",
}) => {
  const intl = useIntl();
  return (
    <ButtonWithTooltip
      className={classNames(
        "hidden h-11 w-[72px] items-center justify-center rounded-[26px] md:flex",
        searchConfig.btnColor[partner] || searchConfig.btnColor.none,
        uhSearchGradientStyle
          ? "hover:bg-gradient-to-r hover:from-[#dd00ff] hover:to-[#544aff]"
          : searchConfig.hoverColor[partner] || searchConfig.hoverColor.none,
        isStickyHeader || uhSearchGradientStyle ? "h-[46px]" : "h-11",
      )}
      dataYlk={{
        elm: "search",
        elmt: "icon",
        itc: 0,
        sec: "ybar",
        slk: "websrch",
        subsec: "searchbox",
        tar: "search.yahoo.com",
      }}
      tooltipOffset={-5}
      tooltipText={intl.formatMessage({ id: "uh.SEARCH_TOOLTIP_WEB" })}
      buttonRef={buttonRef}
    >
      <SearchIcon fill="white" width={20} height={20} />
    </ButtonWithTooltip>
  );
};
