import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import { type FC, useState } from "react";
import {
  CREATOR_HUB_SUBSEC,
  CREATOR_DIRECTORY_SUBSEC,
} from "../../../../apps/content/src/configs/creators";
import { type Item, type List } from "../../lib/l2-navigation-data";
import Chevron from "../../svgs/section-chevron.svg";

interface Props {
  item: Item;
  selected?: boolean;
  itemRef: React.LegacyRef<HTMLLIElement> | undefined;
}

const getDataYlk = (name: string, path: string) => {
  const subNavSubSecValue =
    path && path.includes("directory")
      ? CREATOR_DIRECTORY_SUBSEC
      : CREATOR_HUB_SUBSEC;
  return {
    elm: "navcat",
    itc: 0,
    sec: "ybar",
    slk: name,
    subsec: subNavSubSecValue,
  };
};

const createSubNavItemText = (
  item: Item,
  isPanelHovered: boolean,
  dropdownlinks?: List[],
  selected?: boolean,
) => {
  return (
    <p
      className={classNames(
        selected && "md:text-batcave",
        "flex size-full items-center justify-center text-nowrap text-sm font-[500] not-italic leading-5 text-battleship group-hover/subItem:text-creator-purple group-focus/subItem:text-creator-purple",
        isPanelHovered && "md:text-creator-purple",
        dropdownlinks?.length !== 0 && "w-fit",
      )}
    >
      {item.name}
      {dropdownlinks?.length !== 0 && (
        <span className="ml-px mt-[3px] flex size-2.5">
          <Chevron
            className={classNames(
              "fill-battleship group-hover/subItem:rotate-180 group-hover/subItem:fill-creator-purple group-focus-visible/subItem:rotate-180 group-focus-visible/subItem:fill-creator-purple",
              isPanelHovered && "rotate-180 fill-creator-purple",
            )}
          />
        </span>
      )}
    </p>
  );
};

export const SubNavigationItem: FC<Props> = ({ item, selected, itemRef }) => {
  const [isSubNavPanelVisible, setIsSubNavPanelVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isPanelHovered, setIsPanelHovered] = useState(false);
  const dropdownlinks = item.subLinks;
  const path = usePathname();
  const subNavItemCls =
    "group/subItem h-10 w-full border-transparent border-4 focus-visible:rounded-lg focus-visible:border-4 focus-visible:border-[rgb(96_1_210/50%)] focus-visible:text-creator-purple focus-visible:outline-none";
  return (
    <li
      ref={itemRef}
      className={classNames("relative mr-3.5 flex h-max w-fit flex-col")}
    >
      {item.url ? (
        <I13nAnchor
          className={classNames(subNavItemCls)}
          dataYlk={getDataYlk(item.name, path)}
          href={item.url}
          onBlur={() => {
            setIsSubNavPanelVisible(false);
          }}
          onFocus={() => {
            setIsSubNavPanelVisible(true);
          }}
          onMouseEnter={() => {
            setIsSubNavPanelVisible(true);
          }}
          onMouseLeave={() => {
            setIsSubNavPanelVisible(false);
          }}
        >
          {createSubNavItemText(item, isPanelHovered, dropdownlinks, selected)}
        </I13nAnchor>
      ) : (
        <button
          className={classNames(subNavItemCls)}
          onBlur={() => {
            setIsSubNavPanelVisible(false);
          }}
          onFocus={() => {
            setIsSubNavPanelVisible(true);
          }}
          onMouseEnter={() => {
            setIsSubNavPanelVisible(true);
          }}
          onMouseLeave={() => {
            setIsSubNavPanelVisible(false);
          }}
        >
          {createSubNavItemText(item, isPanelHovered, dropdownlinks, selected)}
        </button>
      )}
      {dropdownlinks && dropdownlinks.length !== 0 && (
        <div
          className={classNames(
            "absolute left-[-22px] top-10 z-[100] flex h-0 w-fit flex-col items-start gap-1 rounded-lg bg-white p-6 opacity-0 shadow-[0px_0px_1px_0px_rgba(0,0,0,0.10),0px_4px_8px_0px_rgba(0,0,0,0.10)] [transition:opacity_.175s_ease-in,_height_.2s_ease-in,_width_.175s_ease-in,_visibility_.175s_ease-in]",
            isSubNavPanelVisible ? "visible opacity-100" : "invisible",
            isSubNavPanelVisible && dropdownlinks.length < 12 && "h-auto",
            isSubNavPanelVisible && dropdownlinks.length > 12 && "h-[472px]",
          )}
          onBlur={() => {
            if (!isFocused) {
              setIsSubNavPanelVisible(true);
            }
          }}
          onFocus={() => {
            setIsSubNavPanelVisible(true);
          }}
          onMouseEnter={() => {
            setIsSubNavPanelVisible(true);
            setIsPanelHovered(true);
          }}
          onMouseLeave={() => {
            setIsPanelHovered(false);
            setIsSubNavPanelVisible(false);
          }}
        >
          <ul
            className={classNames(
              "flex max-h-[430px] min-w-fit flex-col flex-wrap items-start gap-2 p-0",
              dropdownlinks.length > 12 ? "w-[400px]" : "w-max",
            )}
            onBlur={() => {
              setIsFocused(false);
              setIsSubNavPanelVisible(false);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
          >
            {dropdownlinks?.map((item: List, idx: number) => {
              return (
                <li
                  className="group/itemlink flex h-7 w-fit outline-none"
                  key={idx}
                >
                  <I13nAnchor
                    className="flex w-max flex-col items-start justify-center border-4 border-transparent p-1 font-yahoobeta text-sm font-normal text-batcave focus-visible:rounded-lg focus-visible:border-[rgb(96_1_210/50%)] focus-visible:bg-marshmallow focus-visible:outline-transparent group-hover/itemlink:text-creator-purple"
                    dataYlk={getDataYlk(item.name, path)}
                    href={item.url}
                  >
                    {item.name}
                  </I13nAnchor>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};
