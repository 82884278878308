import { I13nAnchor } from "@yahoo-creators/i13n";
import { type MouseEvent, type FC } from "react";
import { getSiteUrl } from "../../lib/logo-data";
import { type Partner } from "../../lib/partners";
import { LogoImg } from "./LogoImg";
interface Props {
  className?: string;
  enableLogoBackNav?: boolean;
  isExperimentalHeader?: boolean;
  imgClassnames?: string;
  lang: string;
  partner?: Partner;
  site?: string;
  stacked?: boolean;
}

const getLogoCls = (
  className: string,
  isExperimentalHeader: boolean,
  partner: string | undefined,
  site: string,
  stacked: boolean,
) => {
  if (isExperimentalHeader) {
    return `${className} md:mr-6`;
  }
  if (partner !== "none") {
    return `${className} md:ml-[5px] lg:ml-5`;
  }
  if (stacked) {
    return `${className} md:mr-[5px] lg:mr-5`;
  }
  return `${className} md:mr-5`;
};

export const Logo: FC<Props> = ({
  className = " focus-visible:rounded focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-8 focus-visible:outline-[rgb(96_1_210/50%)]",
  enableLogoBackNav = false,
  imgClassnames = "",
  isExperimentalHeader = false,
  lang = "en-US",
  partner = "none",
  site = "news",
  stacked = false,
}) => {
  const siteUrl = getSiteUrl(site, lang, partner);
  const logoCls = getLogoCls(
    className,
    isExperimentalHeader,
    partner,
    site,
    stacked,
  );
  const goBack = (e: MouseEvent) => {
    e.preventDefault();
    history.back();
  };

  imgClassnames = imgClassnames || "h-5 md:h-7";

  return (
    <I13nAnchor
      dataYlk={{
        elm: "logo",
        itc: 0,
        sec: "ybar",
        slk: site,
        subsec: "logo",
      }}
      href={siteUrl}
      className={logoCls}
      onClick={(e) => {
        if (enableLogoBackNav) {
          e.preventDefault();
          goBack(e);
        }
      }}
    >
      <LogoImg
        imgClassnames={imgClassnames}
        lang={lang}
        partner={partner}
        site={site}
        stacked={stacked}
      />
    </I13nAnchor>
  );
};
