/**
 * spacing is assumed to be using tailwinds default spacing config
 * https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
 */
import { I13nButton } from "@yahoo-creators/i13n";
import { useState, type FC, useEffect } from "react";
import { useIntl } from "react-intl";
import HamburgerIcon from "../svgs/hamburger.svg";
import { SideNavPanel } from "./SideNavPanel/SideNavPanel";
interface Props {
  isBot: boolean;
  site?: string;
}
export const SideNav: FC<Props> = ({ isBot, site }) => {
  const intl = useIntl();
  const [panelOpen, setPanelOpen] = useState(false);
  const [subNavOpenKey, setSubNavOpenKey] = useState("");
  const [menuLevel, setMenuLevel] = useState(1);
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (panelOpen && event.key === "Escape") {
        setPanelOpen(false);
        setSubNavOpenKey("");
        setMenuLevel(1);
      }
    };

    document.addEventListener("keyup", handleEscapeKey, false);
    return () => {
      document.removeEventListener("keyup", handleEscapeKey, false);
    };
  }, [panelOpen, setPanelOpen, setSubNavOpenKey]);

  useEffect(() => {
    if (panelOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [panelOpen]);

  let autoExpandSection = "";
  switch (site) {
    case "shopping":
    case "lifestyle":
      autoExpandSection = "Life";
      break;
    case "entertainment":
      autoExpandSection = "Entertainment";
      break;
    case "finance":
      autoExpandSection = "Finance";
      break;
    case "sports":
      autoExpandSection = "Sports";
      break;
    case "election":
    case "frontpage":
    case "news":
    case "tech":
      autoExpandSection = "News";
      break;
  }

  return (
    <div className="group flex min-w-min shrink basis-0 items-center justify-start gap-2.5 sm:grow md:mr-4 lg:hidden">
      <I13nButton
        dataYlk={{
          elm: "expand",
          itc: 1,
          pkgt: "top",
          sec: "ybar",
          slk: "hamburger",
          subsec: "navrail",
        }}
        className="-ml-1 mr-3 flex size-5 items-center justify-center border-none bg-transparent focus-visible:rounded focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)] md:ml-0"
        aria-label={intl.formatMessage({ id: "uh.A11Y_SIDENAV_IMAGE" })}
        aria-expanded={panelOpen}
        onClick={() => setPanelOpen(true)}
      >
        <HamburgerIcon
          width={20}
          height={20}
          className="fill-black group-hover:fill-purple-700"
        />
      </I13nButton>
      <SideNavPanel
        isBot={isBot}
        panelOpen={panelOpen}
        setPanelOpen={setPanelOpen}
        setSubNavOpenKey={setSubNavOpenKey}
        subNavOpenKey={subNavOpenKey}
        menuLevel={menuLevel}
        setMenuLevel={setMenuLevel}
        autoExpandSection={autoExpandSection}
      />
      {panelOpen && (
        <div
          aria-hidden="true"
          className="fixed inset-0 z-20 overscroll-auto bg-midnight opacity-60"
          onClick={() => {
            setPanelOpen(false);
            setSubNavOpenKey("");
            setMenuLevel(1);
          }}
        />
      )}
    </div>
  );
};
