import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useEffect, useRef, useState } from "react";
import { type Item, type HiddenProps } from "../../lib/l2-navigation-data";
import { SubNavigationItem } from "./SubNavigationItem";

interface Props {
  setAnimationRunning?: React.Dispatch<React.SetStateAction<boolean>>;
  showSecondLvl: boolean;
  subnavData: Array<Item>;
}

const getDataYlk = (item: Item) => {
  const { name } = item;
  return {
    elm: "navcat",
    itc: 0,
    sec: "ybar",
    slk: name,
    subsec: "subNavigation",
  };
};

const SubNavigationHidden: FC<HiddenProps> = ({ hiddenItems }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <button
        className={classNames(
          "h-full w-10 hover:text-creator-purple focus-visible:rounded-lg focus-visible:border-4 focus-visible:border-[rgb(96_1_210/50%)] focus-visible:text-creator-purple focus-visible:outline-none",
        )}
        onMouseEnter={() => {
          setIsVisible(true);
        }}
        onFocus={() => {
          setIsVisible(true);
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        <p className="flex h-full items-center justify-center text-sm font-[585] not-italic leading-4 text-batcave">
          ...
        </p>
      </button>
      <div
        className={classNames(
          "relative -left-1.5 top-0 z-[100] flex w-fit flex-col items-start gap-1 rounded-lg bg-white p-6 shadow-[0px_0px_1px_0px_rgba(0,0,0,0.10),0px_4px_8px_0px_rgba(0,0,0,0.10)] [transition:opacity_.175s_ease-in,_height_.175s_ease-in,_visibility_.175s_ease-in]",
          isVisible ? "visible h-auto opacity-100" : "invisible h-0 opacity-0",
        )}
        onBlur={() => {
          if (!isFocused) {
            setIsVisible(true);
          }
        }}
        onFocus={() => {
          setIsVisible(true);
        }}
        onMouseEnter={() => {
          setIsVisible(true);
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        <ul
          className="flex list-none flex-col items-start gap-2 p-0"
          onBlur={() => {
            setIsFocused(false);

            setIsVisible(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        >
          {hiddenItems?.map((item: Item, idx: number) => {
            return (
              <li
                className="group/itemlink h-7 w-full hover:bg-marshmallow has-[:focus-visible]:rounded-sm has-[:focus-visible]:bg-marshmallow has-[:focus-visible]:outline has-[:focus-visible]:outline-4 has-[:focus-visible]:outline-[rgb(96_1_210/50%)]"
                key={idx}
              >
                <I13nAnchor
                  className="flex w-max flex-col items-start justify-center gap-2.5 self-stretch whitespace-nowrap p-1 font-yahoobeta text-sm font-normal text-batcave outline-none focus-visible:text-creator-purple group-hover/itemlink:text-creator-purple"
                  dataYlk={getDataYlk(item)}
                  href={item.url}
                >
                  {item.name}
                </I13nAnchor>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export const SubNavigation: FC<Props> = ({
  setAnimationRunning,
  showSecondLvl,
  subnavData,
}) => {
  const [subNavItems, setSubNavItems] = useState<Item[]>(subnavData);
  const [hiddenItems, setHiddenItems] = useState<Item[]>([]);

  const subNavRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<Array<HTMLLIElement>>([]);
  const hiddenItemRef = useRef<HTMLDivElement>(null);
  const subnavRightEdge = subNavRef.current?.getBoundingClientRect()?.right;

  useEffect(() => {
    const el = subNavRef.current;
    if (!el) {
      return;
    }

    const transitionListener = (e: TransitionEvent) => {
      if (e.propertyName === "transform" && setAnimationRunning) {
        setAnimationRunning(true);
      }
    };
    el.addEventListener("transitionstart", transitionListener);
    return () => el.removeEventListener("transitionstart", transitionListener);
  }, [setAnimationRunning]);

  useEffect(() => {
    if (
      subnavRightEdge &&
      hiddenItemRef.current &&
      subnavRightEdge < hiddenItemRef.current.getBoundingClientRect().right
    ) {
      const updateList = subNavItems.filter((item: Item, idx: number) => {
        if (
          subNavItems[subNavItems.length - 1].name !==
          itemRef.current[idx]?.innerText
        ) {
          return item;
        } else {
          setHiddenItems([item, ...hiddenItems]);
        }
      });
      setSubNavItems(updateList);
    } else if (
      subnavRightEdge &&
      hiddenItemRef.current &&
      subnavRightEdge >=
        hiddenItemRef.current.getBoundingClientRect().right + 120
    ) {
      if (hiddenItems.length === 0) return;
      const newHiddenList = hiddenItems.slice(1);
      setSubNavItems([
        ...subNavItems,
        {
          name: hiddenItems[0].name,
          selected: hiddenItems[0].selected,
          subLinks: hiddenItems[0].subLinks,
          url: hiddenItems[0].url,
        },
      ]);
      setHiddenItems(newHiddenList);
    }
  }, [subnavRightEdge, subNavItems, hiddenItems, setHiddenItems]);
  const maxWidthCls = "max-w-uh-custom";
  return (
    <div
      className={classNames(
        showSecondLvl
          ? "pointer-events-auto translate-y-0 opacity-100 [transition:_transform_0.2s_ease,opacity_0.2s_ease-in,margin_0.2s_ease]"
          : "pointer-events-none -mt-10 -translate-y-full opacity-0 [transition:_transform_0.2s_ease,opacity_60ms_ease,margin_0.2s_ease]",
        "inline-flex w-full items-center justify-around bg-[linear-gradient(0deg,_#FFF_0%,_#F8FAFB_100%)]",
      )}
      onTransitionEnd={(e) => {
        if (e.propertyName === "transform" && setAnimationRunning) {
          setAnimationRunning(false);
        }
      }}
    >
      <div
        ref={subNavRef}
        className={classNames(
          "mx-auto flex h-10 w-full items-center bg-[linear-gradient(0deg,_#FFF_0%,_#F8FAFB_100%)]",
          maxWidthCls,
        )}
      >
        <ul
          className={classNames(
            "flex size-full overflow-y-visible px-5 md:ml-[21px] md:px-0 lg:ml-[31px]",
          )}
        >
          {subNavItems?.map((item: Item, index: number) => (
            <SubNavigationItem
              item={item}
              selected={item.selected}
              itemRef={(el: HTMLLIElement) => {
                itemRef.current[index] = el;
              }}
              key={index}
            />
          ))}
          <div
            className={classNames(
              "inline-block h-full max-w-[100px]",
              hiddenItems.length !== 0
                ? "pointer-events-auto opacity-100"
                : "pointer-events-none opacity-0",
            )}
            ref={hiddenItemRef}
          >
            <SubNavigationHidden hiddenItems={hiddenItems} />
          </div>
        </ul>
      </div>
    </div>
  );
};
