import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import Search from "../svgs/search.svg";

interface Props {
  isFullPageSearchOpen: boolean;
  setAnimationRunning?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFullPageSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  showFauxSearch: boolean;
  site?: string;
}

export const FauxSearch: FC<Props> = ({
  isFullPageSearchOpen,
  setAnimationRunning,
  setIsFullPageSearchOpen,
  showFauxSearch,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const placeHolderId = "uh.WEB_SEARCH_PLACEHOLDER";

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const transitionListener = (e: TransitionEvent) => {
      if (e.propertyName === "transform" && setAnimationRunning) {
        setAnimationRunning(true);
      }
    };
    el.addEventListener("transitionstart", transitionListener);
    return () => el.removeEventListener("transitionstart", transitionListener);
  }, [setAnimationRunning]);

  const intl = useIntl();
  return (
    <div
      ref={ref}
      className={classNames(
        "inline-flex w-full items-center justify-end gap-1 self-stretch rounded-[100px] font-yahoobeta transition-[transform_0.4s_ease-out,opacity_0.2s_ease-out] sm:mt-0 sm:border sm:border-dirty-seagull sm:bg-gray-100 sm:transition-none md:hidden",
        showFauxSearch
          ? "px-3 py-1.5"
          : "pointer-events-none -translate-y-full opacity-0",
      )}
      onTransitionEnd={(e) => {
        if (e.propertyName === "transform" && setAnimationRunning) {
          setAnimationRunning(false);
        }
      }}
    >
      <div
        className={classNames(
          "flex shrink grow basis-0 items-center justify-start gap-2 transition-[height_0.4s_ease-out]",
          showFauxSearch ? "h-5" : "h-0",
        )}
      >
        <I13nButton
          dataYlk={{
            elm: "expand",
            itc: 1,
            sec: "ybar",
            slk: "websrch",
            subsec: "searchbox",
          }}
          className="flex h-5 shrink grow basis-0 items-center gap-2 text-sm font-normal leading-tight text-batcave"
          aria-expanded={isFullPageSearchOpen}
          onClick={() =>
            setIsFullPageSearchOpen && setIsFullPageSearchOpen(true)
          }
        >
          <div className="flex items-center justify-center">
            <Search className="fill-batcave" width={20} height={20} />
          </div>
          <span className="hidden sm:flex">
            {intl.formatMessage({ id: placeHolderId })}
          </span>
        </I13nButton>
      </div>
    </div>
  );
};
