"use client";

import { useRapid } from "@yahoo-creators/i13n";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import {
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  type FC,
  type Dispatch,
  type ReactNode,
} from "react";
import { useUHContext } from "../../context/UHContext";
import { type Item } from "../../lib/l2-navigation-data";
import { getPartner, type Partner } from "../../lib/partners";
import { HeaderContext } from "../Account/HeaderContext";
import { Profile } from "../Account/Profile";
import useUsersData from "../Account/useUsersData";
import { CustomSubNavigation } from "../CustomSubNavigation/CustomSubNavigation";
import { FauxSearch } from "../FauxSearch";
import { Logo } from "../Logo/Logo";
import { Mail } from "../Mail/Mail";
import { ModalBackButton } from "../ModalBackButton";
import { PrimaryNav } from "../PrimaryNav";
import { ProgressBar } from "../ProgressBar";
import { Search } from "../Search";
import { type SuggestionsDataFetcher } from "../SearchAssist/types";
import { SideNav } from "../SideNav";
import { Toolbar } from "../Toolbar/Toolbar";
import { SubNavigation } from "./SubNavigation";

interface Props {
  /**
   * A callback for calculating how much of the content has been scrolled
   *
   * _Required_ if you want to display the mweb progress bar
   */
  aboveHeaderAd?: boolean;
  crumb: string;
  customSuggestionsDataFetcher?: SuggestionsDataFetcher;
  customSubNavigation?: ReactNode;
  enablePFS?: boolean;
  initialBGColor?: string;
  profileBgColor?: string;
  isBot?: boolean;
  isModal?: boolean;
  lang?: string;
  mailAppId?: string;
  pageScrollOffset?: number;
  partner?: Partner;
  ratio?: number;
  region?: string;
  scrollThreshold?: number;
  setProgressRef?: RefObject<Dispatch<React.SetStateAction<number>>>;
  showL2Navigation?: boolean;
  site?: string;
  spaceId?: number;
  sticky?: boolean;
  subnavData?: Array<Item>;
  uhSearchGradientStyle?: boolean;
}

const headerClassnames = [
  "top-0",
  "z-20",
  "inline-flex",
  "w-full",
  "flex-col",
  "items-start",
  "bg-white",
  "pt-2.5",
  "md:min-h-[76px]",
  "md:flex-row",
  "md:items-center",
  "md:pt-0",
];

// UH Height - These values are passed to UHContext and used by other components in the app
// When changing UH's height, make sure to keep the values in sync with the CSS
const DESKTOP_HEIGHT = 76;
const SMARTPHONE_WITH_FAUXSEARCH_HEIGHT = 92;
const SMARTPHONE_WITHOUT_FAUXSEARCH_HEIGHT = 48;

export const UH3: FC<Props> = ({
  aboveHeaderAd = false,
  crumb,
  customSuggestionsDataFetcher,
  customSubNavigation,
  enablePFS = false,
  initialBGColor,
  profileBgColor,
  isBot = false,
  isModal = false,
  lang = "en-US",
  mailAppId,
  pageScrollOffset = 44,
  partner = "none",
  ratio,
  region = "",
  scrollThreshold = 5,
  setProgressRef,
  showL2Navigation = false,
  site = "",
  spaceId,
  sticky = true,
  subnavData,
  uhSearchGradientStyle = false,
}) => {
  const {
    isComplete,
    handleSetMailCount,
    handleShowAccountPicker,
    mailCount,
    profileData,
    setUseAlphatar,
    showAccountPicker,
    showSignInButton,
    useAlphatar,
  } = useUsersData({ crumb });
  // Changing partner to a valid one since rogers and none are listed as possible partners
  partner = getPartner(partner, lang);
  const [animationRunning, setAnimationRunning] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [isFullPageSearchOpen, setIsFullPageSearchOpen] = useState(false);
  const [showFauxSearch, setShowFauxSearch] = useState(true);
  const [screenWidth, setScreenWidth] = useState(0);
  const [query, setQuery] = useState("");
  const [noSearchAssistFetch, setNoSearchAssistFetch] = useState(false);
  const { removeShadow, setHeaderHeight } = useUHContext();
  const lastScrollTop = useRef(0);
  const ranCheckOffsetOnce = useRef(false);
  const [hasSearchGradientStyle, setSearchGradientStyle] = useState(
    uhSearchGradientStyle,
  );

  const handleSearchGradientStyleChange = useCallback(
    (useGradient: boolean = false) => {
      setSearchGradientStyle(useGradient);
    },
    [],
  );

  /**
   * aboveHeaderAd is a boolean that determines if there is an ad above the header and we currently have
   * an unwritten agreement that the ad space is 250px tall. This value is used to determine the top value
   */
  const topValue = aboveHeaderAd ? 251 : 1;

  useEffect(() => {
    const docEl = document.documentElement;
    setScreenWidth(window.innerWidth);
    const checkOffset = () => {
      const isDialogOpen = document.body.classList.contains("dialog-open");
      if (isDialogOpen) {
        return;
      }
      // determines if at top of page for UH3 header css stylings
      // will break if UH3 header sticky prop is not true
      setAtTop(window.scrollY < topValue);

      const scrollTop = docEl.scrollTop;
      if (animationRunning) {
        lastScrollTop.current = scrollTop;
        return;
      }
      const afterThreshold = scrollTop > pageScrollOffset;
      const beforeThreshold = scrollTop < pageScrollOffset;
      const scrollingDown = scrollTop > lastScrollTop.current + scrollThreshold;
      const scrollingUp = scrollTop < lastScrollTop.current - scrollThreshold;
      if (
        !showFauxSearch &&
        (beforeThreshold || (afterThreshold && scrollingUp))
      ) {
        setShowFauxSearch(true);
        setAnimationRunning(true);
      } else if (showFauxSearch && afterThreshold && scrollingDown) {
        setShowFauxSearch(false);
        setAnimationRunning(true);
      }

      lastScrollTop.current = scrollTop;
    };
    window.addEventListener("scroll", checkOffset, { passive: true });
    if (!ranCheckOffsetOnce.current) {
      checkOffset();
      ranCheckOffsetOnce.current = true;
    }
    return () => {
      window.removeEventListener("scroll", checkOffset);
    };
  }, [
    animationRunning,
    pageScrollOffset,
    scrollThreshold,
    showFauxSearch,
    topValue,
  ]);

  useEffect(() => {
    if (window.innerWidth < SCREEN_WIDTH.md) {
      if (showFauxSearch) {
        setHeaderHeight(SMARTPHONE_WITH_FAUXSEARCH_HEIGHT);
      } else {
        setHeaderHeight(SMARTPHONE_WITHOUT_FAUXSEARCH_HEIGHT);
      }
    } else {
      setHeaderHeight(DESKTOP_HEIGHT);
    }
  }, [setHeaderHeight, showFauxSearch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth === screenWidth) {
        return;
      }
      setScreenWidth(window.innerWidth);
      if (window.innerWidth < SCREEN_WIDTH.md && !showFauxSearch) {
        setShowFauxSearch(true);
      }
      if (window.innerWidth >= SCREEN_WIDTH.md) {
        setHeaderHeight(DESKTOP_HEIGHT);
      } else {
        setHeaderHeight(SMARTPHONE_WITH_FAUXSEARCH_HEIGHT);
      }
    };

    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, [screenWidth, showFauxSearch, setHeaderHeight]);
  const handleQueryChange = (newquery: string, noFetch: boolean = false) => {
    setNoSearchAssistFetch(noFetch);
    setQuery(newquery);
  };
  const id = "module-uh";
  useRapid(id, "ybar");
  const maxWidthCls = "max-w-uh-custom";
  const isShopping = site === "shopping";

  return (
    <header
      id={id}
      className={classNames(
        headerClassnames,
        !isShopping && "font-centra",
        initialBGColor &&
          "[transition:background-color_0.12s_ease,_box-shadow_0.12s_ease]",
        !isModal && initialBGColor && atTop ? initialBGColor : "",
        sticky ? "sticky" : "relative",
        showFauxSearch ? "md:py-0" : "pb-1 md:pb-0",
        atTop
          ? ""
          : !removeShadow &&
              "shadow-[0_4px_8px_0_rgba(0,0,0,0.1),0_0_1px_0_rgba(0,0,0,0.1)]",
        showL2Navigation &&
          "md:flex md:max-h-[136px] md:flex-col md:justify-start md:gap-0 md:px-0 md:pb-0",
        !showL2Navigation && "px-5 md:gap-6 md:px-0",
      )}
    >
      <div
        className={classNames(
          showL2Navigation && "md:px-5",
          "mx-auto inline-flex w-full items-center justify-around px-6 pb-2 sm:pb-0 md:h-[76px] lg:px-8",
          maxWidthCls,
        )}
      >
        {isModal ? <ModalBackButton /> : <SideNav isBot={isBot} site={site} />}
        {partner !== "none" && (
          <>
            <Logo lang={lang} partner={partner} site={site} />
            <div className="mx-2 h-5 w-px bg-dirty-seagull"></div>
          </>
        )}
        <Logo
          enableLogoBackNav={isModal}
          lang={lang}
          partner="none"
          site={site}
          stacked={partner !== "none"}
        />

        {/* Desktop Search */}
        <Search
          crumb={crumb}
          customSuggestionsDataFetcher={customSuggestionsDataFetcher}
          isFullPageSearchOpen={isFullPageSearchOpen}
          isStickyHeader={true}
          lang={lang}
          setIsFullPageSearchOpen={setIsFullPageSearchOpen}
          site={site}
          onQueryChange={handleQueryChange}
          partner={partner}
          query={query}
          noSearchAssistFetch={noSearchAssistFetch}
          isModal={isModal}
          initialSearchInputFocus={true}
          uhSearchGradientStyle={uhSearchGradientStyle}
          hasSearchGradientStyle={hasSearchGradientStyle}
          onSearchGradientStyleChange={handleSearchGradientStyleChange}
        />
        <HeaderContext.Provider value={{ lang, region, site, spaceId }}>
          <PrimaryNav
            lang={lang}
            isBot={isBot}
            isStickyHeader={true}
            partner={partner}
            site={site}
          />
          <Toolbar>
            <Mail
              crumb={crumb}
              handleSetMailCount={handleSetMailCount}
              isComplete={isComplete}
              lang={lang}
              mailAppId={mailAppId}
              mailCount={mailCount}
              partner={partner}
            />
            {/*Mobile Search */}
            <div className="flex sm:hidden">
              <FauxSearch
                setIsFullPageSearchOpen={setIsFullPageSearchOpen}
                isFullPageSearchOpen={isFullPageSearchOpen}
                showFauxSearch={showFauxSearch}
                setAnimationRunning={setAnimationRunning}
                site={site}
              />
            </div>
            <Profile
              crumb={crumb}
              enablePFS={enablePFS}
              profileBgColor={profileBgColor}
              profileData={profileData}
              setUseAlphatar={setUseAlphatar}
              handleShowAccountPicker={handleShowAccountPicker}
              mailCount={mailCount}
              showAccountPicker={showAccountPicker}
              showSignInButton={showSignInButton}
              useAlphatar={useAlphatar}
            />
          </Toolbar>
        </HeaderContext.Provider>
      </div>
      {/*Tablet Search */}
      <div className="hidden w-full bg-white px-5 py-3 sm:flex md:py-0">
        <FauxSearch
          setIsFullPageSearchOpen={setIsFullPageSearchOpen}
          isFullPageSearchOpen={isFullPageSearchOpen}
          showFauxSearch={showFauxSearch}
          setAnimationRunning={setAnimationRunning}
          site={site}
        />
      </div>
      {showL2Navigation && subnavData && (
        <SubNavigation
          setAnimationRunning={setAnimationRunning}
          showSecondLvl={showFauxSearch}
          subnavData={subnavData}
        />
      )}
      {showL2Navigation && !subnavData && customSubNavigation && (
        <CustomSubNavigation
          setAnimationRunning={setAnimationRunning}
          showSecondLvl={showFauxSearch}
          customSubNavigation={customSubNavigation}
        />
      )}
      {(typeof ratio === "number" || !!setProgressRef) && (
        <ProgressBar ratio={ratio} ref={setProgressRef} />
      )}
    </header>
  );
};
